/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Dzi_Data_EntriesItem.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "патанатомия". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.container {
    margin-left: 5vw;
    margin-right: 5vw;
    min-height: 100vh;
    margin-bottom: 150px;
}

.case_preview_title1 {
    color: rgb(247, 247, 247);
    font-size: 1.2vw;
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
}

.dzi_items_flex {
    margin: 0px auto;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    margin-bottom: 50px;
}

.dzi_items_flex.filter_list {
    display: grid;
    gap: 1px;
    flex-wrap: nowrap;
    justify-content: stretch;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    width: auto;
}

.demo_version_tif_preview {
    background: rgb(28, 107, 255);
    position: absolute;
    margin: 15px;
    padding: 14px;
    border-radius: 12px;
    color: #ffffff;
    z-index: 1;
    font-size: 20px;
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
}

.case_preview-item1 {
    border-radius: 20px;
    cursor: pointer;
    height: 320px;
    min-width: 10vw;
    overflow: hidden;
    position: relative;
    width: 22vw;
}


.case_preview-item1 img {
    width: 170%;
    height: 150%;
    object-fit: cover;
    position: relative;
    transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
}

.case_preview-item1 img:hover {
    transform: scale(1.3);
}

.case_preview_info1 {
    line-height: 3vh;
    border: 1px solid rgba(255, 255, 255, 0.094);
    backdrop-filter: blur(3px) saturate(150%);
    -webkit-backdrop-filter: blur(15px) saturate(170%);
    background-color: rgba(63, 88, 135, 0.181);
    border-top-right-radius: 30px;
    margin-right: 20px;
    position: absolute;
    padding: 30px;
    bottom: 0px;
    height: auto;
}

@media (max-width: 1600px) {
    .case_preview_info1 {
        line-height: unset;
    }
    .case_preview-item1.filter_list {
        width: 19vw;
    }
}

@media (max-width: 1400px) {
    .case_preview-item1.filter_list {
        width: unset;
    }
}

@media (max-width: 1200px) {
    .dzi_items_flex {
        margin: 0px auto;
        width: fit-content;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .dzi_items_flex.filter_list {
        grid-template-columns: repeat(2, 1fr);
    }
    .case_preview-item1 {
        width: 100%;
    }
    .case_preview_info1 {
        width: 100%;
        padding: 25px;
    }
    .case_preview_title1 {
        line-height: 24px;
        font-size: 14px;
    }
}

@media (max-width: 1024px) {
    .container{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
    }
    .dzi_items_flex {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
    .dzi_items_flex.filter_list {
        grid-template-columns: repeat(2, 1fr);
    }
    .case_preview-item1 {
        height: 250px;
    }
    .case_preview_info1 {
        padding: 20px;
    }
}


@media (max-width: 568px) {
    .dzi_items_flex {
        grid-template-columns: repeat(1, 1fr);
    }
    .dzi_items_flex.auscultation {
        grid-template-columns: repeat(2, 1fr);
    }
    .container {
        margin: auto 20px 50px 20px;
        width: unset;
    }
    .case_preview_info1 {
        padding: 15px;
    }
    .case_preview_title1 {
        line-height: 2vh;
        font-size: 13px;
    }
    .case_preview_latin_name1 {
        margin-bottom: 0px;
    }
    .case_preview-item1 {
        height: 200px;
    }
}

@media(max-width: 479px) {
    .dzi_items_flex.filter_list {
        display: flex;
        flex-direction: column;
    }
    .dzi_items_flex.auscultation {
        grid-template-columns: repeat(1, 1fr);
    }
}