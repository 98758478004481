/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_Options_Table.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". ECG_Data_Options_Table.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.table_ekg_normal_static_parent {
    border-radius: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    height: fit-content;
    padding: 5px;
    color: #1b7ae6;
    border: 1px solid rgba(218, 218, 218, 0.397);
    background-color: rgb(242, 242, 242);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 3px;
    grid-row-gap: 3px;

}

@media only screen and (max-width: 600px) {
    /* .table_ekg_normal_static_parent {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 3px;
        grid-row-gap: 3px;
    } */

    /* .tb_ekg_normal_1 {
        grid-area: 1 / 1 / 2 / 2;
    }


    .tb_ekg_normal_1 {
        grid-area: 1 / 2 / 2 / 3;
    }


    .tb_ekg_normal_1 {
        grid-area: 2 / 1 / 3 / 2;
    }


    .tb_ekg_normal_1 {
        grid-area: 2 / 2 / 3 / 3;
    }


    .tb_ekg_normal_1 {
        grid-area: 3 / 1 / 4 / 2;
    }


    .tb_ekg_normal_1 {
        grid-area: 3 / 2 / 4 / 3;
    } */


}

.table_ekg_normal_static_parent_grid5 {
    color: #1b7ae6;
    border-radius: 25px;
    margin-bottom: 10px;
    height: fit-content;
    padding: 5px;
    background: #ececec;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 3px;
    grid-row-gap: 3px;
}


.tb_ekg_normal_1,
.tb_ekg_normal_2,
.tb_ekg_normal_3,
.tb_ekg_normal_4,
.tb_ekg_normal_5,
.tb_ekg_normal_6 {
    width: auto;
    height: auto;
    padding: 15px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
}

.tb_ekg_normal_column {
    font-size: 27px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.tb_ekg_normal_column_dynamic {
    font-size: 18px;
    line-height: 20px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.tb_ekg_normal_column_ed {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-family: Inter-Regular, Arial, Helvetica, sans-serif;

}

.tb_ekg_normal_val {
    color: rgb(0, 0, 0);
    padding-top: 5px;
    font-size: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.tb_ekg_normal_ed {
    color: rgb(0, 0, 0);
    font-size: 17px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.tb_ekg_normal_val6 {
    color: #000000;
    line-height: 20px;
    padding-top: 5px;
    font-size: 12px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.table_header {
    font-size: 25px;
    font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
}

.table_collapse_button {
    cursor: pointer;
    border-radius: 15px;
    background-color: rgb(239, 239, 239);
    padding: 12px;
    font-size: 18px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    margin-bottom: 10px;
    transition: all 0.1s ease-in-out;
}


.table_collapse_button:hover {
    color: rgb(17, 73, 255);
    background-color: rgb(211, 217, 233);
}

.collapse_img {
    margin-left: 5px;
    width: 35px;
}



/* Для экранов шириной до 479px (мобильные устройства) */
@media(max-width: 479px) {
    .table_collapse_button {
        font-size: 15px;
    }

}

@media(max-width: 1024px) {
    .table_ekg_normal_static_parent_grid5,
    .table_ekg_normal_static_parent {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 498px) {
    .flex_item_1 {
        font-size: 24px;
        width: unset;
    }
    table_ekg_normal_static_parent_grid5,
    .table_ekg_normal_static_parent {
        grid-template-columns: repeat(3, 1fr);
    }
    .swiper-slide img.ekg_picture {
        background-color: rgb(225, 230, 245);
        padding: 3px;
        border-radius: 10px;
        margin-bottom: 50px;
        width: 100%;
        height: 250px;
        object-fit: cover;
        justify-content: center;
        z-index: 1;
    }
    .tb_ekg_normal_column {
        font-size: 18px;
    } 
    .tb_ekg_normal_ed,
    .tb_ekg_normal_val {
        padding: 0px;
        font-size: 15px;
    }
}