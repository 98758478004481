/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: AccountPage.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Файлы относящиеся к страницам сайта "мой профиль". AccountPage.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
.profile {
    padding: 0px;
}

.profile .header {
    font-size: 4rem;
    background-color: #516ae4;
    color: white;
    padding: 30px;
}

.profile .header .small {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin-top: 20px;
    width: 500px;
    font-size: 17px;
}

.profile .header .title {
    margin-left: 20px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 65px;
}

.profile .content {
    min-height: 50vh;
    display: flex;
}

.profile .content .menu {
    width: 350px;
    padding-top: 10px;
    background-color: #f1f4fb;
    font-size: 21px;
    font-family: Inter-SemiBold, Arial, Helvetica, sans-serif;
    min-height: 50vh;
}

.profile .content .menu button {
    margin: 2px;
    text-align: start;
    width: 330px;
    border: none;
    background-color: #ffe4c400;
    margin-left: 10px;
    border-radius: 20px;
    color: #373737;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.profile .content .menu button:hover {
    color: #006eff;
    background-color: #ffffff91;
}

.profile .content .menu button.active {
    color: #006eff;
    background-color: #ffffff;
}

.profile .content .block {
    padding: 50px;
    min-height: 50vh;
    width: 850px;
    font-family: Inter-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.profile .heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.profile .profile-info .group {
    margin: 1rem 0;
    padding: 1rem;
    border-style: none;
    border-radius: 20px;
    background-color: white;
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.profile .profile-info .group.university, .profile .profile-info .group.school {
    background-color: #0a6398;
    background-image: url('/public/icons/account_bg_university.png');
    background-repeat: no-repeat;
    background-position: 30rem -3rem;
    background-size: 50%;
    color: white;
}

.profile .profile-info .group.moodle {
    background-color: #f2a96d;
    background-image: url('/public/icons/moodle_icon_white.png');
    background-repeat: no-repeat;
    background-position: 31rem 1rem;
    background-size: 35%;
    color: white;
}

.profile .profile-info .group .heading {
    font-size: 1.8rem;
    user-select: none;
}

.testing_results .entry {
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 0;
    margin-bottom: 10px;
}

.testing_results .entry .head {
    color: #1955f8;
    font-size: 1.3rem;
    border-radius: 10px;
    padding: 1rem;
    background-color: #d7e4ff;
    display: flex;
    justify-content: space-between;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
}

.testing_results .entry .head .actions {
    margin-top: 10px;
}

.testing_results .entry .head .title_and_date .date {
    font-size: 0.9rem;
    color: #6e6e6e;
}

.testing_results .entry .data {
    font-size: 1rem;
    max-height: 30vh;
    overflow: scroll;
    padding: 1rem;
    color: #959595;
}

.testing_results .entry .data .row {}

.testing_results .entry .data .row .title {
    margin-top: 1rem;
    color: #959595;
}

.testing_results .entry .data .row .answers {
    color: #000000;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.form-control {
    display: block;
    width: 100%;
    font-size: 1.3rem !important;
    padding-top: 3rem !important;
    padding-bottom: 1.2rem !important;
}

.profile .profile-info .row {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 15px;
}
.profile-info .col-auto {
    flex: 0 0 auto;
    width: auto;
    font-size: 25px !important;
}

.users_block .table .input-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
}

.users_list_filter {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.users_list_filter .row {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.users_list_filter .input-group {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid rgba(219, 219, 219, 0.494);
    flex-grow: 1;
}

.mass_create_field .form.mt-2{
    display: flex;
    flex-direction: row;
    align-content: flex-end;
}

.mass_create_field .row .btn{
    margin-left: 20px
}

.mass_create_field .form-control {
    border-radius: 12px;
    padding-top: 10px !important;
    padding-bottom:5px !important;
    font-size: 18px !important;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: #333;
    background-color: transparent;
    outline: none;
    flex-grow: 1;
    transition: all 0.3s ease;
    height: 50px;
    margin-left: 0px;
    width: 300px;
}

.users_list_filter .form-control {
    border-radius: 12px;
    padding-top: 10px !important;
    padding-bottom:5px !important;
    font-size: 18px !important;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: #333;
    background-color: transparent;
    outline: none;
    flex-grow: 1;
    transition: all 0.3s ease;
}

.users_list_filter .form-control::placeholder {
    color: #999;
}

.users_list_filter .form-control:focus {
    box-shadow: none;
    background-color: #f8f9fa;
}

.users_list_filter .btn {
    border: none;
    background-color: transparent;
    color: #666;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users_list_filter .btn:hover {
    background-color: #f8f9fa;
    color: #333;
}

.users_list_filter .btn-outline-secondary {
    color: #666;
    padding: 0.5rem 1.5rem;
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid  #99999991;
}

.users_list_filter .btn-outline-secondary:hover {
    border-color: #56585e;
    background-color: #f8f9fa;
}

.users_list_filter .alert {
    margin-top: 1rem;
    border-radius: 12px;
    padding: 1rem;
    background-color: #fff3f3;
    border: 1px solid #ffcccc;
    color: #ff4444;
}

.users_list_filter .alert-heading {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

@media (max-width: 1500px) {
    .profile .content .block {
        width: 70%;
    }
    .profile .content .menu {
        width: 20vw;
        font-size: 19px;
    }
    .profile .content .menu button {
        width: 18vw;
    }
    .col-3.text-body-secondary {
        width: 50%;
    }
    .profile .profile-info .group.university {
        background-position: 16rem 1.3rem;
        background-size: 70%;
        margin-top: 40px;
    }
    .profile .profile-info .group.moodle {
        background-position: 16rem 2rem;
        background-size: 50%;
    }
    .profile .heading {
        font-size: 2rem;
        font-weight: 600;
    }
    .profile .header .title {
        font-size: 50px;
    }
    .profile .header img {
        width: 70px;
        height: 70px;
    }
    .profile .profile-info .row {
        flex-direction: column;
        margin-top: 20px;
    }
    .profile-info .col-auto {
        flex: 0 0 auto;
        width: auto;
        font-size: 20px !important;
    }
    .profile-info .col-3.text-body-secondary {
        width: 50%;
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .users_list_filter .input-group {
        width: 100%;
    }
    .users_list_filter .btn-outline-secondary {
        width: 100%;
    }
    .profile .heading {
        width: 100%;
        font-size: 2rem;
    }
    .profile .header .small {
        width: 100%;
        font-size: 17px;
    }
    .profile .content {
        flex-direction: column;
        width: 100%;
    }
    .profile .header {
        width: 100%;
        height: fit-content;
    }
    .profile .content .block {
        padding-left: 2.7rem;
        padding-right: 1.3rem;
        min-height: 50vh;
        width: 100%;
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 80px;
    }
    .profile.content.block.users_block {
        padding-left: 20px;
        padding-right:20px;
    }
    .profile .content .menu {
        max-width: 100%;
        width: 100%; 
        min-height: 70px; 
        display: flex;
        justify-content: space-around; 
        position: relative; 
        padding: 10px; 
        font-size: 17px;
    }
    .profile .content .menu button {
        width: 70vw; 
        margin-left: 0; 
        padding: 10px; 
        text-align: center;
    }
    .profile .profile-info .row {
        flex-direction: row;
    }
    .users_block .table-container {
        width: 100%; 
        overflow-x: auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
    }
    .users_block .table {
        width: 100%;
        min-width: 600px;
        border-collapse: collapse;
    }
    .users_block .table th {
        padding: 1rem;
        background-color: #f8f9fa;
        border-bottom: 2px solid #dee2e6;
        text-align: left;
        font-weight: 600;
        color: #333;
        font-size: small;
    }
    .users_block .table td {
        padding: 1rem;
        border-bottom: 1px solid #dee2e6;
        color: #555;
        font-size: medium;
    }
    .users_block .table-hover tbody tr:hover {
        background-color: #f1f3f5; 
    }
    .users_block .table .btn {
        margin: 0 0.25rem;
        padding: 0.375rem 0.75rem;
        font-size: small;
        border-radius: 8px;
        transition: all 0.3s ease;
    }
    .users_block .table .input-group {
        flex-wrap: nowrap;
    }
    .users_block .table .btn-outline-secondary {
        border: 1px solid #ddd;
        color: #666;
    }
    .users_block .table .btn-outline-secondary:hover {
        background-color: #f8f9fa;
        border-color: #ccc;
        color: #333;
    }
    .users_block .table .btn-outline-danger {
        border: 1px solid #ff6b6b;
        color: #ff6b6b;
    }
    .users_block .table .btn-outline-danger:hover {
        background-color: #ff6b6b;
        color: #fff;
    }
}

@media (max-width: 620px) {
    .profile .content .menu {
        flex-direction: column;
        align-items: center
    }
    .profile .header {
        font-size: 3.5rem;
    }
    .profile .header .title {
        font-size: 10vw;
        line-height: 50px;
    }
    .profile .header img {
        width: 48px;
        height: 48px;
    }
    .profile .profile-info .row {
        flex-direction: column;
    }
    .mass_create_field .form.mt-2,
    .users_list_filter .row{
        flex-direction: column;
        align-items: flex-start
    }
    .mass_create_field .row .btn {
        margin-left: 0px;
    }
    .addnew_block .form-label,
    .addnew_block .form-control,
    .users_list_filter .form-control,
    .mass_create_field .row .form-control,
    .users_block p {
        font-size: medium !important;
    }
    .profile .heading {
        font-size: 1.3rem !important;
    }
}