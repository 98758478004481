/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: AgeBar.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. AgeBar.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.track-style {
    background-color: grey;
}

.rail-style {
    background-color: grey;
}

.handle-style {
    box-shadow: none;
    background-color: blue;
}


.ages_list_title {
    color: rgb(80, 80, 80);
    margin-bottom: 5px;
    font-size: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    user-select: none;
}


.ages_list_slider {
    user-select: none;
    border: 1px solid rgba(0, 0, 0, 0.067);
    border-radius: 15px;
    padding: 20px 10px;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
}

.Slider_age {
    margin: 10px;
    grid-area: 1 / 1 / 2 / 2;
}


.ages_list_slider_numbers {
    grid-area: 1 / 2 / 2 / 3;
    display: block;
    padding-left: 40px;
    font-size: 22px;
    color: #979797;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    user-select: none;
}

@media (max-width: 678px) {
    .filters_block {
        height: fit-content;
    }
    .ages_list_slider {
        padding: 10px 7px;
    }
}

@media (max-width: 479px) {
    .ages_list {
        padding: 0px;
        padding: 10px;
    }
    .container {
        padding: 0px;
    }
    .ages_list_slider {
        padding: 10px 7px;
    }
    .ages_list_slider_numbers {
        grid-area: 1 / 2 / 2 / 3;
        display: block;
        padding-left: 50px;
        font-size: 16px;
    }
}