.custom-background_menu_bio {
    height: 100%;
    background-color: rgb(225, 230, 235);
}

.sub_category_flex {
    margin-top: 15px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.sub_category_bottom_flex {
    padding-bottom: 150px;
}

.sub_category_title {
    background-color: rgb(66, 133, 235);
    color: #ffffff;
    padding: 80px;
    font-size: 80px;
    font-family: InterTight-Regular;
    animation-name: example;
    animation-duration: 20s;
    position: relative;
    animation-name: example;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    margin-bottom: 50px;
}

@keyframes example {
    from {
        top: -30px;
    }

    to {
        top: 0px;
    }
}

.sub_category_rightpage_info {
    margin: auto;
    padding: 10px;
    width: 1090px;
}

.sub_category_rightpage_info_2 {
    color: rgb(55, 55, 55);
    border-radius: 25px;
    padding: 20px;
    backdrop-filter: blur(15px) saturate(100%);
    -webkit-backdrop-filter: blur(20px) saturate(100%);
    background-color: rgba(241, 241, 241, 0.384);
    border: 1px solid rgba(209, 213, 219, 0.3);
    font-family: InterTight-Regular;
    font-size: 22px;
    width: 100%;
}

.sub_category_rightpage_info_title {
    width: 1000px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 20px;
    margin-bottom: 20px;
}

@keyframes show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sub_category_rightpage_flex {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
}

.sub_category_main_pic img {
    border-radius: 30px;
    margin: 10px auto;
    width: 100%;
    display: block;
    margin-bottom: 50px;
}

/* .sub_category_card-list {
    display: flex;
    flex-direction: column;
} */

.sub_category_card-name {
    font-size: clamp(1rem, 3vw, 1.5rem);
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    color: rgb(100, 100, 100);
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sub_category_card-items {
    background-color: rgba(255, 255, 255, 0.185);
    backdrop-filter: blur(20px) saturate(100%);
    -webkit-backdrop-filter: blur(20px) saturate(100%);
    border: none;
    margin: 1px;
    width: 350px;
    height: 350px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.1s ease;

    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.sub_category_card-icon {
    display: flex;
    justify-content: center;
}

.sub_category_card-icon img {
    width: 60%;
    animation: customAni 1s ease 0s normal both;
}

@keyframes customAni {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(5px);
    }
}

@media (max-width: 1200px) {
    .sub_category_bottom_flex {
        padding-bottom: 100px;
    }
    .sub_category_rightpage_info {
        width: auto;
    }
    .sub_category_rightpage_info {
        margin-inline: 60px;
        padding: 10px;
        width: 100%;
    }
    .sub_category_rightpage_info_2 {
        font-size: 18px;
    }
    .sub_category_rightpage_flex {
        justify-content: center;
        display: flex;
        margin: 10px auto;
    }
    .sub_category_bottom_flex {
        display: flex;        
        justify-content: center;
    }
    .sub_category_card-list {
        display: flex;
        flex-direction: row;
    }
    .sub_category_card-items {
        margin: 1px;
        width: 250px;
        height: 250px;
        border-radius: 30px;
        font-size: 17px;
    } 
    .sub_category_card-name {
        font-size: 18px;
    }
}

@media (max-width: 992px) {
    .sub_category_title {
        padding: 60px;
        font-size: 40px;
        justify-items: center;
        margin-bottom: 0px;
    }
    .sub_category_card-items {
        margin: 1px;
        width: 200px;
        height: 200px;
        border-radius: 30px;
        font-size: 17px;
    } 
    .sub_category_card-name {
        font-size: 18px;
    }
}

@media (max-width: 789px) {
    .sub_category_rightpage_info {
        margin-inline: 40px;
    }
    .sub_category_title {
        padding: 60px;
        font-size: 36px;
        justify-items: center;
    }
    .sub_category_title {
        padding: 50px;
        font-size: 36px;
        justify-items: center;
    }
    .sub_category_rightpage_info_2 {
        font-size: 17px;
    }
    .sub_category_card-items {
        margin: 1px;
        width: 180px;
        height: 180px;
        border-radius: 30px;
        font-size: 17px;
    } 
    .sub_category_card-name {
        font-size: 16px;
        line-height: 20px;
    }
}
@media (max-width: 589px) {
    .sub_category_card-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1px; 
        justify-content: center;
    }
    .sub_category_rightpage_info {
        margin-inline: 10px;
    }
    .sub_category_title {
        padding: 20px;
        padding-bottom: 30px;
        line-height: 30px;
        text-align: center;
        display: grid;
        margin: 0px auto;
        justify-content: center;
        font-size: 30px;
    }
    .sub_category_card-items {
        width: 150px;
        height: 150px;
        border-radius: 30px;
    } 
    .sub_category_card-name {
        font-size: 15px;
    }
}