.news-page {
    display: flex;
    width: 100%;
    min-height: 1000px;
    /* margin: 0 auto 0 100px; */
    padding: 20px 3vw 100px 5vw;
    gap: 20px;
    align-items: baseline;
    background-color: #e5eaf3;
}

.news_page-title {
    user-select: none;
    color: rgb(69, 69, 69);
    font-size: 75px;
    font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
    padding: 30px 0;
}

.news-content {
    flex: 1; 
    max-width: 800px; 
}

.news_page-content {
    overflow: hidden;
    position: relative;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
    will-change: opacity;
}

.news-item {
    display: flex;
    margin-bottom: 20px;
    padding: 15px 50px 15px 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.news-item-image img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 20px;
    margin-top: 10px;
}

.news-item-text {
    flex: 1;
}

.news-item h3 a{
    text-decoration: none;
    color: inherit; 
}

.news-item h3 a:hover {
    color:#024e80; 
}

.news-item-text p{
    flex: 1;
    text-align: left;
    font-size: 18px;
    margin-top: 0.9rem;
}

.news-item-date {
    display: block;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
}

.news-item-link {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.news-item-link:hover {
    background-color: #007bff;
}

.news-sidebar {
    font-family: 'Inter', sans-serif; 
    background: #fcfbfb64;
    border-radius: 14px;
    padding: 25px; 
    width: 400px; 
    max-width: 100%;
    height: fit-content;
    margin-right: 5vw;
    border: 1px solid rgba(255, 255, 255, 0.2); 
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .news-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .news-sidebar li:hover {
    color: #024e80; 
  }
  
  .news-categories-container {
    position: relative;
    width: 100%;
    height: 760px;
    overflow: hidden;    
    border-radius: 15px;
    backdrop-filter: blur(15px); 
    
    mask-image: linear-gradient(to bottom, transparent 0%, black 15%, black 85%, transparent 100%);
    margin-top: 20px;
    backdrop-filter: blur(40px); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .news-categories-container ul {
    position: absolute;
    padding-left: 25px;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    animation: scrollVertical 35s linear infinite;
  }
  
  @keyframes scrollVertical {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  
  .news-about_categories_line {
    font-size: 18px;
    padding-bottom: 15px;
    color: #272626; 
    padding-left: 2px;
    transition: transform 0.4s ease; 
    cursor: pointer
  }

  .news-about_categories_line:hover {
    transform: translateX(10px); 
  }
  
  .statistics {
    flex-shrink: 0;
    padding: 25px;
    font-size: 32px;
    color: #0a0a0a;
    border: 1px solid rgba(91, 90, 90, 0.2);
    border-radius: 8px;
    transition: border-color 0.4s ease, color 0.4s ease; 
}

.statistics h3 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 600;
    transition: color 0.4s ease; 
}

.statistics p {
    margin: 8px 0;
    font-size: 18px;
    transition: color 0.4s ease; 
}

.statistics:hover {
    border-color:#027680; 
    transition: all 0.4s ease-in-out;
}

.statistics h3:hover,
.statistics p:hover {
    color: #024e80;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 20px 0;
    margin: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination-button {
    display: inline-block;
    padding: 8px 15px;
    background-color: #f3f3f3;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    margin-inline: 5px;
    border-width: 0px;
}

.pagination-button:hover {
    background-color:#d9dadc;
    color: #333;
}

.pagination-button.active {
    background-color: #2a75a6;
    color: white;
    font-weight: bold;
}

.pagination .disabled .pagination-button {
    background-color: #d9dadc;
    color: #6c757d;
    cursor: not-allowed;
}

@keyframes scrollHorizontal {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
}

@media (max-width: 1200px) {
    .news-sidebar {
        padding: 10px;
        width: 300px;
        margin-right: 2vw;
    }
}

@media (max-width: 992px) {
    .news_page-title {
        font-size: 50px;
        padding: 30px;
    }
    .news-sidebar {
        padding: 10px;
        width: 260px;
        margin-right: 1vw;
    }
}

@media (max-width: 992px) {
    .news-page {
        flex-direction: column-reverse;
        padding-inline: 0px;
        align-items: center;
    }
    .news-content {
        margin-inline: 20px;
    }
    .news-item-text p {
        font-size: 16px;
    }
    .news-page.news-sidebar{
        padding: 0px;
    }
    .news-sidebar {
        z-index: 150;
        width: 100%;
        max-width: unset;
        padding: 10px;
    }
    .statistics{
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .statistics h3{
        display: none;
        
    }
    .statistics p{
        font-size: 24px;
        font-weight: bold;
    }
  
    .news-categories-container {
        height: 100px; 
        mask-image: linear-gradient(to right, transparent 0%, black 15%, black 85%, transparent 100%);
    }
    .news-categories-container ul {
        flex-direction: row;
        animation: scrollHorizontal 20s linear infinite; 
    }
    .news-sidebar li{
        padding-inline: 15px;
        text-align: center;
    }
    .news_page-title {
        user-select: none;
        color: rgb(69, 69, 69);
        font-size: 55px;
        font-family: 'InterTight-SemiBold', Arial, Helvetica, sans-serif;
        padding: 20px 35px;
    }
    .news-sidebar,
    .news-categories-container {
        border-radius: 0px;
    }
    .news-sidebar {
        width: 100%;
        margin-bottom: 20px;
    }
    .news-item-image {
        margin-bottom: 10px;
    }
    .pagination-button {
        font-weight: unset;
    }
}

@media (max-width: 600px) {
    .news-item h3 a {
       font-size: 22px;
    }
    .news-item {
        flex-direction: column;
        text-align: left;
        padding-inline: 30px;
    }
    .news-item-text p {
        text-align: left;
        font-size: 15px;
    }
    .statistics p{
        font-size: 20px;
        
    }
    .news-item-image img {
        width: 80px;
        height: 80px;
        margin-top: o;
    }
}