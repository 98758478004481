/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_EntriesItem.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.ecg_data_entries_item_info {
    padding: 20px;
    width: 320px;
    height: 320px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.data_entries_item_info {
    padding: 20px;
    height: 300px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.icon_and_case {
    padding: 0px;
    flex-direction: row;
}


::marker {
    color: rgb(62, 125, 221);
}

.ecg_data_entries_hr {
    color: rgb(179, 179, 179);
}

.ecg_data_entries_item-name {
    color: rgb(56, 91, 165);
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 27px;
}

.data_entries_item-name{
    font-size: 22px;
}

.ecg_data_entries_item-datebirth {
    display: flex;
    align-items: end;
    color: rgb(95, 95, 95);
    font-size: 20px;
}

.num_datebirth {
    color: #006eff;
    position: relative;
    padding-left: 5px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
}


.ecg_data_entries_item-items {
    background-color: rgb(246, 246, 246);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out
}

.ecg_data_entries_item-items:hover {
    background-color: rgb(231, 231, 231);
}

.ecg_data_entries_item-gender {
    color: rgb(95, 95, 95);
    font-size: 15px;
}

.ecg_data_entries_item:hover {
    background-color: rgb(238, 245, 255);
    box-shadow: 3px 6px 25px rgba(0, 0, 0, 0.2);
}

.icon_patient img {
    margin-bottom: 10px;
    max-width: 35%;
}

.ecg_demo {
    position: absolute;
    font-size: 16px;
    margin-top: -20px;
    margin-left: 150px;
    padding: 10px;
    width: auto;
    border-radius: 14px;
    color: rgb(255, 255, 255);
    background-color: rgb(35, 97, 252);
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

@media(max-width: 789px) {
    .ecg_data_entries_item_info {
        padding: 15px;
        width: 90%; 
        height: auto; 
        font-size: 16px; 
    }
    .icon_and_case {
        padding: 0;
        flex-direction: column;
        align-items: center; 
    }
    .ecg_data_entries_item-name {
        font-size: 24px;
    }
    .data_entries_item_info {
        height: 280px;
    }
    .data_entries_item-name{
        font-size: 18px;
    }
    .ecg_data_entries_item-datebirth {
        font-size: 18px; 
    }
    .num_datebirth {
        font-size: 18px; 
        padding-left: 0; 
    }
    .ecg_data_entries_item-items {
        background-color: rgb(246, 246, 246);
        border-radius: 30px; 
        padding: 10px; 
    }
    .ecg_data_entries_item-gender {
        font-size: 14px; 
    }
    .icon_patient img {
        margin-bottom: 15px; 
        max-width: 50%; 
    }
    .icon_patient img {
        margin-bottom: 15px; 
        max-width: 50%; 
    }
    .icon_patient img {
        margin-bottom: 15px; 
        max-width: 42%; 
    }
    .ecg_demo {
        font-size: 14px; 
        margin-top: -10px; 
        margin-left: 10px; 
        padding: 8px;
        border-radius: 12px; 
    }
}

@media(max-width:479px) {
    .icon_patient img {
        max-width: 40%;
    }
    .ecg_demo {
        margin-left: 48px;
        margin-top: -212px;
        position: absolute;
        font-size: 15px;
        padding: 10px;
        width: fit-content;
        border-radius: 15px;
    }
    .ecg_data_entries_item_info {
        width: fit-content;
    }
}
