.audio_block {
    border-radius: 15px;
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    padding: 20px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: rgb(43, 43, 43);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 80px;
}

.audio_controls {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 15px;
    align-items: center;
}

.play_repeat_controls {
    display: flex;
    gap: 10px;
}

.control_button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    transition: color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    border: 1px solid rgba(219, 219, 219, 0.494);
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
}

.control_button:hover {
    background-color: #007bff1b;
    border: 1px solid #007bff02;
    transform: scale(1.1);
}

.control_button.active {
    background-color: #007bff1b;
    border: 1px solid #007bff02;
}

.control_button:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.control_button img {
    width: 24px;
    height: 24px;
    transition: filter 0.3s ease;
}

.control_button:hover img {
    filter: brightness(0.8);
}

.seek_slider {
    flex-grow: 1;
    height: 4px;
    background: #e0e0e0;
    border-radius: 2px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.seek_slider:hover {
    opacity: 1;
}

.seek_slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.seek_slider::-webkit-slider-thumb:hover {
    background: #005bb5;
    transform: scale(1.2); 
}

.time_display {
    font-size: 14px;
    color: #666;
    min-width: 80px;
    text-align: center;
}

.volume_controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

.volume_slider {
    width: 80px;
    height: 4px;
    background: #e0e0e0;
    border-radius: 2px;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s ease;
}

.volume_slider:hover {
    opacity: 1;
}

.volume_slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: #007bff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.volume_slider::-webkit-slider-thumb:hover {
    background: #005bb5; 
    transform: scale(1.2); 
}

.case_text_info img{
    width: 25px;
    height: 25px;
    margin: 0 0 12px 6px;
}

@media (max-width: 600px) {
    .audio_controls {
        flex-direction: column-reverse;
        align-items: center;
    }
    .play_repeat_controls {
        flex-direction: row; 
        gap: 10px; 
    }
    .control_button{
        font-size: 16px; 
        width: 50px;
        height: 50px;
        padding: 6px;
    }
    .control_button img {
        width: 20px; 
        height: 20px;
    }
    .seek_slider {
        width: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .time_display {
        font-size: 12px;
    }
    .volume_controls {
        display: none;

    }
    .volume_slider {
        width: 60%; 
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .case_text_info img{
        width: 20px;
        height: 20px;
        margin-bottom: 9px;
    }
    .control_button:active {
        background-color: transparent !important;
    }
    
    .control_button.active {
        background-color: #007bff1b !important;
    }

}