/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_Con_Parameters_Table.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". ECG_Data_Con_Parameters_Table.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.table_ekg_bd {
    display: flex;
    font-size: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.table_ekg_name {
    font-size: 15px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    padding: 0 0 10px 0;
}

@media only screen and (max-width: 900px) {
    .table_ekg_bd {
        font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
        font-size: 12px;
        padding: 10px;
        overflow-y: scroll;
        width: 100%;
    }
}