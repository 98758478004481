/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: NavigationBreadcrumbs.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. NavigationBreadcrumbs.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
.table_link {
    margin-top: 20px;
    padding: 10px 15px;
    width: fit-content;
}

.breadcrumbs_hr {
    margin-top: 45px;
    border-top: 2px solid #d0d0d0;
}

.table_link_name {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 22px;
    color: rgb(144, 144, 144);
}

@media (max-width: 1024px) {
    .table_link {
        margin-top: 20px;
        padding: 0px;
        width: fit-content;
    }
    .table_link_name {
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .table_link_name {
        font-size: 14px;
    }
}