/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestingPage.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Файлы относящиеся к тестам. TestingPage.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
.testing_page {
    background-color: rgba(229, 234, 243, 1);
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.testing_page_grid {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 3px;
}

.testing_page_grid.wide {
    grid-template-columns: auto;
}

.tp_block_sidebar {
    font-size: 20px;
    border-radius: 30px;
    height: auto;
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
}

.tp_block_sidebar_title{
    font-size: 32px;
    font-family: Arial, Helvetica, sans-serif;
}

.tp_block_sidebar .tp_block_sidebar_menu {
   display: flex;
   justify-content: space-evenly;
}

.tp_block_sidebar_selected {
    margin: 2px;
    border-radius: 30px;
    padding: 20px;
    background-color: #f1f1f181;
    transition: all 0.3s ease-in;
    cursor: pointer;
    width: 50%;
    height: fit-content;  
}

.tp_block_sidebar_selected:hover {    
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0);
    text-decoration: none;
}

.tp_block_sidebar_selected.active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: default;
    background-color: #d5e6f9;
    transform: translateY(0); 
}

.tp_block_sidebar_selected:hover:not(.disabled) {
    background-color: #d5e6f9;
}

.tp_block_sidebar_selected.disabled .tp_block_sidebar_selected_name {
    color: #718191;
}

.tp_block_sidebar_selected_name {
    border: none;
    background-color: transparent;
    color: #005eff;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
    font-size: 22px;
    width: auto;
}

.question_number {
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 26px;
    user-select: none;
}

.test_progress_name {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 26px;
}

.time_left {
    margin-top: 15px;
    padding: 5px;
    border-radius: 30px;
    width: 125px;
    display: flex;
    justify-content: center;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 30px;
    color: white;
    background-color: #3a79e4;
}

.tp_block_sidebar_selected_description {
    color: #4d4d4d;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.tp_block_sidebar_selected_tasks {
    grid-area: 1 / 3 / 2 / 4;
    color: #464646;
    padding-top: 1.5rem;    
    text-align: right;
}

.tp_block_user_header {
    border-radius: 30px;
    width: 100%;
    height: fit-content;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-template-rows: repeat(2, 0fr);
    grid-column-gap: 10px;
    grid-row-gap: 3px;
    background-color: #ffffff;
}

.tp_block_user_header_photo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
}

.tp_block_achievement {
    display: grid;
    grid-template-columns: repeat(2, 80px);
    grid-column-gap: 0px;
}

.tp_block_achievement_icon {
    width: 64px;
    padding: 8px;
    border-radius: 8px;
    grid-area: 1 / 1 / 2 / 2;
}

.tp_block_achievements_desc {
    line-height: 30px;
    font-size: 17px;
    margin-bottom: 30px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.tp_block_achievements_medals{
    display: flex;
    justify-content: space-between;
}

.tp_block_achievement_title {
    grid-area: 1 / 2 / 2 / 3;
    font-size: 18px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.tp_block_achievement_nums {
    font-size: 21px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.tp_block_achievement {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.tp_block_achievement .tp_block_achievement_icon {
    width: 30px;
    height: 30px;
    padding: 0px;
}

.tp_block_achievement_title {
    font-size: 16px;
    font-weight: bold;
}

.tp_block_achievments_title {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #2c72ea;
    font-size: 23px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.tp_block_user_header_info {
    font-size: 16px;
    margin-left: 20px;
    width: fit-content;
}

.tp_block_user_header_name {
    min-width: 330px;
    line-height: 40px;
    font-size: 32px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.tp_block_user_header_faculty {
    font-size: 20px;
    margin-bottom: 20px;
}

.tp_block_hr {
    color: #d0d0d0;
    width: auto;
}

.tp_block_courses {
    display: inline-table;
    border-radius: 30px;
    padding: 20px;
    background-color: #ffffff;
    width: 100%;
}

/* Блок с информацией о категориях тестирования, в "выключенном" состоянии. */
:root {
    --courses-block-disabled-bg-color: rgb(255, 255, 255);
    --courses-block-disabled-font-color: rgba(0, 0, 0, 0.2);
}

.tp_block_courses.disabled .tp_block_courses_selected {
    background-color: var(--courses-block-disabled-bg-color);
    color: var(--courses-block-disabled-font-color);
    cursor: default;
    user-select: none;
}

.tp_block_courses.disabled .tp_block_sidebar_selected_name {
    background-color: var(--courses-block-disabled-bg-color);
    color: var(--courses-block-disabled-font-color);
}

.tp_block_courses.disabled .tp_block_sidebar_selected_tasks {
    background-color: var(--courses-block-disabled-bg-color);
    color: var(--courses-block-disabled-font-color);
}

.tp_block_courses.disabled .tp_block_sidebar_selected_description {
    background-color: var(--courses-block-disabled-bg-color);
    color: var(--courses-block-disabled-font-color);
}

.tp_block_courses.disabled .tp_block_courses_pic {
    filter: grayscale(100%) opacity(20%);
}

.tp_block_stats {
    font-size: 18px;
    display: flex;
    gap: 30px;
    color: #555555;
    transition: all 0.4s ease-in-out;
}

/* .tp_block_stats_active {
    cursor: pointer;
}

.tp_block_stats_active:hover {
    color: #3f73ed;
} */

.tp_block_courses_selected {
    height: 110px;
    display: grid;
    grid-template-columns: repeat(1, 100px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 30px;
    padding: 20px;
    margin-bottom: 2px;
    background-color: #f1f1f1;
    align-items: center;
}

.tp_block_courses_pic {
    grid-area: 1 / 1 / 2 / 2;
    width: 75px;
}

.tp_block_courses_selected:hover {
    background-color: #d5e6f9;
}

.tp_block_achievments {
    font-size: 20px;
    border-radius: 30px;
    background-color: #ffffff00;
    padding: 28px;
    width: 100%;
}

.text-center {
    padding: 10px;
    font-size: 40px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.btn-primary1 {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    border: none;
    padding: 15px;
    font-size: 20px;
    width: 225px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    color: #3d445d;
    border-radius: 25px;
}

.text-small-num {
    text-align: center;
    color: #1f60d1;
    font-size: 48px;
    font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
}

.text-center-grid {
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 100px;
}

.text-small {
    color: #a3b1c8;
    border: 1px solid rgba(211, 219, 238, 0.259);
    width: auto;
    padding: 30px;
    margin: 5px;
    background-color: #f5f8fe;
    font-size: 20px;
    border-radius: 35px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.text-grid {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    flex-direction: row;
}

.tp_block_courses_pagination {
    color: #444444;
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    background-color: transparent;
    cursor: pointer;
    width: 140px;
    margin: 0px auto;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
}

.tp_block_courses_pagination:hover {
    color: #ffffff;
    background-color: rgb(203, 203, 203);
}

.testing_page .questions {
    width: 60%;
    margin: 0 auto;
}

.testing_page .mode_selection {
    width: 100%;
    text-align: center;
    margin-top: 4rem;
}

.testing_page .category_selection {
    width: 100%;
    text-align: center;
    margin-top: 4rem;
}

.testing_page .mode_selection .buttons {

    width: 20%;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
}

.testing_page .category_selection .buttons {
    width: 20%;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
}

.testing_page .category_selection .buttons button {
    border: none;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 118, 254);
    padding: 15px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 21px;
    transition: background-color 0.2s ease;
    text-decoration: none;
    margin: 0.5rem;
}

.testing_page .mode_selection .buttons button {
    border: none;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 118, 254);
    padding: 15px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 21px;
    transition: background-color 0.2s ease;
    text-decoration: none;
    margin: 0.5rem;
}

.testing_page .mode_selection .buttons button:hover {
    background-color: #dde6f0;
}

.testing_page .category_selection .buttons button:hover {
    background-color: #e3e3e3;
}

.testing_page .questions_wrapper {
    display: flex;
}

.testing_page .questions_wrapper .test_progress {
    margin-left: 100px;
    width: 310px;
    position: sticky;
    top: 11rem;
    max-height: 55vh;
    margin-bottom: 170px;
}

.btn_test_end {
    background-color: #005eff;
    border: none;
    padding: 10px;
    width: 200px;
    color: white;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 21px;
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
}

.btn_test_end:hover {
    background-color: #d23415;
}

.testing_page .questions_wrapper .test_progress h2 {
    width: 60%;
    margin: 0 auto;
}

.testing_page .questions_wrapper .test_progress .answers_wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 30vh;
    overflow-y: scroll;
    padding-bottom: 4rem;

    mask: linear-gradient(to bottom,
            rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%,
            rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
}

.testing_page .questions_wrapper .test_progress .answers_wrapper .answer {
    background-color: #d1dce7;
    border-radius: 10px;
    margin: 0.2rem;
    color: #79808c;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
    padding: 8px;
    user-select: none;
    width: 3rem;
    height: 3rem;
    text-align: center;
}

/* Стиль для элемента бокового списка вопросов, в том случае, когда это является текущим вопросом. */
.testing_page .questions_wrapper .test_progress .answers_wrapper .answer.disabled {
    cursor: default;
    background-color: #ffffff;
}

/* Стиль для элемента бокового списка вопросов, в том случае, когда на этот вопрос был дан ответ. */
.testing_page .questions_wrapper .test_progress .answers_wrapper .answer.answered {
    cursor: default;
    background-color: #a7c8e9;
}

.testing_page .questions_wrapper .test_progress .answers_wrapper .answer:hover:not(.disabled) {
    cursor: pointer;
    background-color: #ecf1fc;
}

.testing_page .questions_wrapper .questions .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.testing_page .questions .controls {
    display: flex;
    justify-content: space-between;
}

.testing_page .questions .controls button {
    min-width: 10rem;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(55, 118, 254);
    padding: 15px;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    font-size: 21px;
    transition: background-color 0.2s ease;
    text-decoration: none;
    margin: 0.5rem;
}

.testing_page .questions .controls button:hover {
    background-color: #e3e3e3;
}

.testing_page .questions .controls button:disabled {
    background-color: inherit;
    cursor: default;
    color: inherit;
}

.testing_page .results {
    margin-top: 2rem;
    font-size: 1.4rem;
}

.testing_page .results .overall {
    margin-bottom: 2rem;
}

@media (max-width: 1400px) {
    .testing_page .questions_wrapper{
        display: block;
        padding: 15px;
    }
    .testing_page .questions_wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .testing_page .questions_wrapper .test_progress {
        width: 70%;
        position: unset; 
        top: 0; 
        max-height: 55vh;
        margin: 0 0 20px 0;
    }
    .testing_page .questions {
        width: unset;
    }
    .testing_page .questions_wrapper .test_progress .answers_wrapper {
        margin-top: 20px;
        margin-bottom: 10px;
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        height: unset;
        overflow-y: scroll;
        padding-bottom: 1rem;
        mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;
    }
}

@media (max-width: 900px) {
    .testing_page {
        display: flow-root;
    }
    .testing_page_grid {
        align-items: center;
        padding: 30px 50px 0px 50px;
        margin-top: 0px;
    }
    .tp_block_user_header {
        height: auto;
    }
    .tp_block_user_header_photo {
        width: 5rem;
        height: auto;
        border-radius: 20px;
    }
    .tp_block_sidebar {
        height: 250px;
    } 
    .tp_block_sidebar_title {
        font-size: 28px;
    }
    .tp_block_sidebar_selected {
        padding: 20px;
        height: 120px;
    }
    .tp_block_sidebar .tp_block_sidebar_menu{
        display: flex;
        height: 100px;
    }
    .tp_block_sidebar_selected_name {
        border: none;
        background-color: transparent;
        color: #005eff;
        font-family: InterTight-SemiBold, Arial, Helvetica, sans-serif;
        font-size: 22px;
        width: 100%;
    }
    .tp_block_courses_selected {
        grid-template-columns: 75px 1fr; 
        grid-template-rows: auto auto; 
        height: fit-content; 
        padding: 15px;
        align-items: center;
    }
    .tp_block_courses_pic {
        grid-area: 1 / 1 / 2 / 2; 
        align-self: start; 
    }
    .tp_block_courses_selected .tp_block_sidebar_selected_name {
        grid-area: 1 / 2 / 2 / 3; 
        text-align: center;
    }
    .tp_block_sidebar_selected_description {
        grid-area: 2 / 2 / 3 / 3; 
        font-size: 14px; 
    }
    .tp_block_sidebar_selected_tasks {
        grid-area: 3 / 1 / 4 / 3; 
        text-align: left; 
        padding-top: 10px; 
        padding-left: 10px;
    }
    .tp_block_sidebar_menu .tp_block_achievement {
        align-items: center;
        flex-direction: column;
    }
    .tp_block_achievement_title {
        text-align: center;
    }
    .question_number {
        font-size: 22px;
    }
    .testing_page .questions .controls button,
    .btn_test_end,
    .time_left{
        font-size: 18px;
        padding: 10px;
    }
    .testing_page .questions_wrapper .test_progress .answers_wrapper {
        flex-direction: row;
        flex-wrap: nowrap;
        width: 85vw;
        height: unset;
        overflow-x: visible;
    }
    .testing_page .questions_wrapper .test_progress .answers_wrapper .answer {
        width: 40px;
        min-width: 40px;
        margin: 1px;
    }
    .testing_page .questions_wrapper .test_progress {
        width: 95%;
    }
}

@media (max-width: 600px) {    
    .testing_page .questions .controls {
        flex-direction: column;
    }
    .testing_page_grid {
        width: 100%;
        padding: 30px 10px 0px 10px;
    }
    .tp_block_user_header_name {
        min-width: 230px;
    }
    .tp_block_stats {
        font-size: 16px;
    }
    .tp_block_achievments {
        height: fit-content;
        margin-bottom: 20px;
    }
    .tp_block_achievements_medals {
        display: grid;
        grid-template-columns: repeat(2, 2fr); 
        grid-column-gap: 20px; 
        grid-row-gap: 10px; 
        justify-items: center;
    }
    .tp_block_achievement {
        display: flex;
        align-content: center;
        width: 84px;
    }
    .tp_block_sidebar_menu .tp_block_achievement {
        justify-content: center;
    }
    .tp_block_achievements_medals
    .tp_block_hr{
        display:none;
    }
    .tp_block_achievements_desc {
        line-height: 25px;
        font-size: 16px;
    }
    .tp_block_achievement_title {
        font-size: 16px;
        text-align: center;
        width: 100px;
    }
    .tp_block_achievement_nums {
        font-size: 17px;
    }
    .tp_block_sidebar {
        height: fit-content;
    }
    .tp_block_sidebar .tp_block_sidebar_menu {
        display: flex;
        height: fit-content;
        flex-direction: column;
        align-items: center;
    }
    .tp_block_sidebar_selected{
        width: 100%;
        height: unset;
        border-radius: 18px;
    }
    .tp_block_courses {
        display: grid;
    } 
    .tp_block_courses_selected {
        height: fit-content;
        display: flex;    
        align-items: center;
        flex-direction: column;
        border-radius: 22px;
    }
    .tp_block_courses_pic {
        align-self: center;
    }
    .tp_block_user_header_info {
        width: 50vw;
    }
    .tp_block_user_header_photo {
        width: 4rem;
        height: auto;
        border-radius: 15px;
    }
    .tp_block_sidebar_selected_name {
        width: 60vw;
    }    
    .tp_block_sidebar_selected_name {
        font-size: 18px;
    }
    .tp_block_sidebar_selected_tasks {
        font-size: 14px;
    }
    .tp_block_sidebar_title {
        font-size: 24px;
    }
    .testing_page .questions_wrapper {
        padding: 0px;
    }
    
    .testing_page .questions_wrapper .test_progress .answers_wrapper {
        width: 90vw;
    }
}

@media (max-width: 460px) {  
    .testing_page {
        display: flex;
    }
    .testing_page_grid .wide{
        align-items: center;
        padding-inline: unset;
    }
    .tp_block_user_header_name {
        font-size: 22px;
    }
    .tp_block_user_header_info,
    .tp_block_user_header_exp,
    .tp_block_user_header_tasks {
        grid-column: 1 / 4; 
        margin-left: 0px;
        width: 100%;
    }
    .tp_block_user_header .tp_block_achievement {
        grid-column: 1 / 3; 
    }
    .tp_block_sidebar_title {
        font-size: 22px;
        line-height: 30px;
    }
    .tp_block_stats {
        font-size: 15px;
    }
    .tp_block_achievements_desc {
        font-size: 16px;
    }
    .tp_block_achievments_title {
        font-size: 20px;
    }
    .tp_block_achievments {
        font-size: 20px;
        border-radius: 30px;
        background-color: #ffffff00;
        padding: 12px;
    }
}