/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Terms_Page.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Terms_Page.css
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович,
*/
.terms_grid {
    user-select: none;
    width: 90vw;
    border-radius: 10px;
    border: 1px solid rgb(240, 240, 240);
    margin: 80px auto;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.user_guide_block_button {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    border: none;
    margin-bottom: 40px;
    margin-top: 10px;
    font-size: 0.8rem;
    font-family: 'SF-Pro-Display-Medium';
    background-color: rgb(240, 243, 247);
    transition: all 0.2s ease-in-out;
}

.user_guide_block_button:hover {
    background-color: rgb(215, 220, 228);
}

.user_guide_block_button img {
    width: 2rem;
}

.terms_sidebar {
    width: 21em;
    height: auto;
    border-right: 1px solid rgb(240, 240, 240);
    grid-area: 2 / 1 / 3 / 2;
    transition: transform 0.3s ease-in-out;
}

.terms_sidebar_block {
    padding: 25px;
    line-height: 2.5;
}

.terms_sidebar.open {
    transform: translateX(0);
}

.terms_sidebar_title_img {
    display: none;
}

.terms_sidebar_menu {
    border-radius: 10px;
    padding-left: 10px;
    padding-top: 20px;
    font-size: 1.1em;
    font-family: 'SF-Pro-Display-Medium';
    cursor: pointer;
    animation: all 0.2s ease-in-out;
    line-height: 1.0;
}

.terms_sidebar_menu:hover {
    color: rgb(26 59 108);
    cursor: pointer;
}

.active {
    color: rgb(26 59 108);
}

.terms_side_sidebar_content {
    font-size: 20px;
    font-family: 'SF-Pro-Display-Light';
    display: flex;
    align-items: center;
}

.terms_sidebar_content {
    display: none;
}

.terms_side_sidebar_content img {
    width: 28px;
    margin-right: 10px;
}

.close-button {
    display: none;
}

.terms_main {
    width: 100%;
    height: fit-content;
    grid-area: 2 / 2 / 3 / 3;
}

.terms_main_block {
    padding: 40px;
}

.terms_main_title {
    width: 100%;
    padding: 16.4px 40px;
    border-bottom: 1px solid rgb(240, 240, 240);
    font-family: 'SF-Pro-Display-SemiBold';
    grid-area: 1 / 2 / 2 / 3;
    font-size: 50px;
}

.terms_main_name_title {
    font-family: 'SF-Pro-Display-Regular';
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
}

.terms_main_desc {
    margin-bottom: 50px;
    margin-top: 25px;
    font-size: 20px;
    font-family: 'SF-Pro-Display-Light';
    line-height: 1.5;
}

.screen_menu {
    width: 700px;
    height: auto;
    margin: 20px;
    border-radius: 15px;
    left: 0px;
}

.screen_menu_map {
    max-width: fit-content;
    margin: 10px;
    left: 0px;
}

.screen_string {
    color: rgb(126, 126, 126);
    font-size: 1.0em;
    display: flex;
    /* justify-content: center; */
    font-family: 'SF-Pro-Display-Medium';
}

@media (max-width: 1240px) {
    .screen_menu {
        width: 90%;
    }
    .screen_string {
        font-size: 16px;
    }
}

@media (max-width: 1023px) {
    .terms_grid {
        width: 100vw;
        margin: 0px;
        margin-top: 42px;
        margin-bottom: 30px;
        justify-content: center;
    }
    .terms_main {
        width: 80vw;
        height: fit-content;
        margin-left: 40px;
    }
    .terms_main_title {
        width: auto;
        padding-inline: 0px;
        font-size: 38px;
        margin-bottom: 30px;
        text-align: center;
    }
    .terms_main_block {
        padding: 0px;
    }
    .terms_sidebar {
        width: 300px;
        position: fixed;
        z-index: 2;
        background-color: white;
        height: 100vh;
        transform: translateX(-180%);
    }
    .terms_sidebar_content {
        width: fit-content;
        max-width: 100%;
        cursor: pointer;
        font-size: 20px;
        font-family: 'SF-Pro-Display-Light';
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px;
        margin-top: 2px;
        margin-left: auto;
        padding-inline: 10px;
        position: fixed;
        z-index: 20;
        top: 122px;
        left: 0px;
        border-radius: 10px;
        background-color: #f0f3f7ed;
        border: 1px solid rgba(214, 214, 214, 0.111);
        transition: box-shadow 0.3s ease;
    }
    .terms_sidebar_content_img {
        width: 35px;
        padding-inline: 0px;
        margin-right: 8px;
    }
    .hidden {
        display: none;
    }
    .close-button {
        display: flex;
        position: absolute;
        top: 5px;
        right: 5px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
    }
    .terms_sidebar_content:hover {
        cursor: pointer;
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
    .screen_menu {
        width: 80%;
        padding: 0px;
    }
}

@media (max-width: 876px) {
    .terms_sidebar_content {
        top: 120px;
    }
}

@media (max-width: 689px) {
    .screen_menu {
        width: 70%;
    }
    .terms_main {
        width: 90vw;
    }
    .terms_main_title,
    .terms_main_name_title,
    .terms_main_desc {
        padding-right: 30px;
    }
    .terms_main_title {
        font-size: 24px;
    }
    .terms_main_name_title {
        font-size: 26px;
    }
    .terms_main_desc {
        font-size: 18px;
    }
}

@media (max-width: 591px) {
    .terms_sidebar_content {
        top: 131px;
    }
}