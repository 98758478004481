/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ProtectedImageSlider.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. В этом файле находится компонент, отвечающий за вывод защищённого изображения для кейса.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
.button_slider_block {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
}

.prev_slider {
    background-color: rgba(245, 245, 220, 0);
    margin: 1px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    border: none;
    border-radius: 20px;
    transition: all 0.1s ease;
}

.next_slider {
    background-color: rgba(245, 245, 220, 0);
    margin: 1px;
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 20px;
    border: none;
    border-radius: 20px;
    transition: all 0.1s ease;
}

.prev_slider img {
    transform: scaleX(-1);
    width: 60px;
    height: 60px;
    border-radius: 20px;
    margin: 5px;

}

.next_slider img {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    margin: 5px;

}

.prev_slider:hover {
    background-color: rgb(225, 225, 225);
}

.next_slider:hover {
    background-color: rgb(225, 225, 225);
}

.swiper-slide img.ekg_picture {
    background-color: rgb(225, 230, 245);
    padding: 3px;
    border-radius: 10px;
    margin-bottom: 50px;
    width: 100%;
    max-height: 350px;
    object-fit: cover;
    justify-content: center;
    z-index: 1;
}

.swiper-slide img {
    width: 100%;
    margin: 30px auto;
    max-height: 500px;
    display: flex;
    justify-content: center;
    object-fit: cover;
    position: relative;
    z-index: 0;
}


.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.swiper-container-no-flexbox .swiper-slide {
    float: left;
}

.swiper-container-vertical>.swiper-wrapper {
    flex-direction: column;
}

.swiper {
    width: 100%;
    height: 100%;

}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #ffffff00;
    display: flex;
    justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
    margin: 10px;
}