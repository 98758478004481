/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Radiology_Appeal_Block.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "лучевая диагностика". Radiology_Appeal_Block.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
.radiology_appeal_block {
    color: rgb(43, 43, 43);
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    font-size: 27px;
    height: 100%;
    padding-top: 1rem;
}

.radiology_appeal_number_button {
    margin: 8px;
    border: 1px solid rgba(177, 181, 218, 0.463);
    background-color: rgb(219, 228, 255);
    color: rgb(0, 47, 255);
    padding: 17px;
    font-size: 18px;
    width: fit-content;
    font-family: InterTight-Medium, Arial, Helvetica, sans-serif;
    border-radius: 50px;
    transition: all 0.1s ease-in-out;
}

.radiology_appeal_number_button:hover {
    background-color: rgb(194, 203, 232);
}

.radiology_appeal_number_flex {
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    gap: 8px;
}

.radiology_appeal_block .radiology_appeal_number {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_appeal_block .radiology_appeal_anamnesis {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
}

.radiology_appeal_block .radiology_appeal_anamnesis .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_appeal_block .radiology_appeal_anamnesis .text {
    font-size: 17px;
    text-align: justify;
}

.radiology_appeal_block .radiology_appeal_labdiag {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
    margin-top: 1rem;
}

.radiology_appeal_block .radiology_appeal_labdiag .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_appeal_block .radiology_appeal_labdiag .text {
    font-size: 17px;
    text-align: justify;
}

.radiology_appeal_block .radiology_appeal_treatment {
    border: 1px solid rgba(219, 219, 219, 0.494);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    padding: 15px;
    margin-top: 1rem;
}

.radiology_appeal_block .radiology_appeal_treatment .title {
    font-family: InterTight-Regular, Arial, Helvetica, sans-serif;
    width: fit-content;
    font-size: 17px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(25, 106, 237);
}

.radiology_appeal_block .radiology_appeal_treatment .text {
    font-size: 17px;
    text-align: justify;
}

@media (max-width: 489px) {
    .radiology_appeal_block .radiology_appeal_anamnesis .text,
    .radiology_appeal_block .radiology_appeal_labdiag .text,
    .radiology_examination_block .radiology_examination_description .text{
        font-size: 15px;
        text-align: justify;
    }
}
