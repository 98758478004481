/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Quiz.css
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Quiz.css
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
.quiz {
    height: fit-content;
    width: 900px;
    padding: 30px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 40px;
    background-color: #ffffff;
}

.correct_answer_hint {
    margin-top: 15px;
    font-family: 'SF-Pro-Display-Medium';
}

.correct_answer {
    border-radius: 15px;
    font-family: 'SF-Pro-Display-Regular';
    padding: 30px;
    color: #22c047;
    background-color: #e6fce6;
}

.quiz_answer_text_selected {
    font-size: 20px;
    font-family: 'SF-Pro-Display-Light';
}

.quiz .image_dzi_or_ekg img {}



.quiz .hint {
    font-family: 'SF-Pro-Display-Light';
    font-size: 20px;
    margin: 1rem auto;
    width: 90%;
    color: #5d5d5d;
}

.quiz .heading {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 25px;
    line-height: 33px;
    color: #303030;
    margin-bottom: 10px;
}

.quiz .options {
    margin: 0px auto;
    width: 90%;
}

.quiz .options .button {
    text-align: left;
    border: 1px solid rgb(172, 172, 172);
    border-radius: 7px;
    display: flex;
    width: 100%;
    border: none;
    background-color: #f3f3f3;
    color: #424242;
    font-family: 'SF-Pro-Display-Light';
    font-size: 18px;
    padding: 12px;
    margin-bottom: 3px;
}

.quiz .options .button.btn_selected {
    color: #0044ff;
    background-color: #d1dfffc2;
}


.quiz .options .button:hover {
    color: #006aff;
    background-color: #e5e9e9;
}

.quiz .options .button:disabled:hover {
    background-color: #f3f3f3;
    color: #424242;
}

/*
.quiz .options .button:disabled {
    cursor: default;
    color: #ff5900;
    background-color: #ffd4af;
}
*/
.quiz .options .button:hover:enabled:not(.disabled) {
    cursor: pointer;
}

.quiz .options .button.correct {
    background-color: #d4f4d4;
    color: #1cca1c;
    cursor: default;
}

.quiz .options .button.incorrect {
    background-color: #ffe3e3;
    color: #e72323;
    cursor: default;
}

.quiz .options .button.correct:disabled {
    background-color: #b1eeb1;
    color: #03b403;
}

.quiz .options .button.incorrect:disabled {

    background-color: #ffe3e3;
    color: #e72323;
}

.quiz .options .button.correct_no_chosen:disabled {
    background-color: #e8f3e8;
    color: #2fa82f;
    cursor: default;
}

.quiz .options .button:disabled {
    cursor: default;
}




.quiz .options .text {
    font-size: 25px;
    width: 100%;
}

.quiz .options .text.correct {
    background-color: #d4f4d4;
    color: #1cca1c;
}

.quiz .options .text.incorrect {
    background-color: #ffe3e3;
    color: #e72323;
}

.quiz .results {
    font-size: 1.3rem;
    margin: 1rem auto;
    width: 90%;
}

.quiz .results .answers_selected {}

/* При демо-доступе */

.quiz.demo {
    font-family: 'SF-Pro-Display-Light';
    font-size: 15px;
    color: #e1e1e1;
}

.quiz.demo .hint {
    color: #e1e1e1;
}

.quiz.demo .heading {
    color: #d7d7d7;
}

.quiz.demo .options .button {
    color: #d7d7d7;
    padding: 5px 20px;
    width: auto;
    margin: 10px auto;
    background-color: inherit;
    border: 1px solid #d7d7d7;
}

.quiz.demo .options .button:hover {
    cursor: default;
    pointer-events: none;
}

.quiz .demo_text {
    position: relative;
    top: -12vh;
    left: 0;
    bottom: 0;
    font-size: 1.4rem;
    color: rgb(28, 28, 28);
    user-select: none;
    z-index: 100;
    backdrop-filter: blur(5px) saturate(100%);
    -webkit-backdrop-filter: blur(2px) saturate(100%);
}

.btn_results {
    color: #3b3b3b;
    padding: 5px 20px;
    width: auto;
    margin-left: 44px;
    background-color: inherit;
    border: 1px solid #161616;
    border-radius: 5px;
}

.btn_results:hover {
    color: #ffffff;
    background-color: rgb(75, 75, 75);
    border: 1px solid #ffffff;
}

@media (max-width: 1400px) {
    .quiz {
        width: unset;
    }
}

@media (max-width: 600px) {
    .quiz {
        padding: 20px;
    }
    .quiz .heading {
        font-size: 20px;
        line-height: 25px;
    }
    .quiz .hint {
        font-size: 18px;
    }
    .quiz .options .button {
        text-align: left;
        border: 1px solid rgb(172, 172, 172);
        border-radius: 7px;
        display: flex
    ;
        width: 100%;
        border: none;
        background-color: #f3f3f3;
        color: #424242;
        font-family: 'SF-Pro-Display-Light';
        font-size: 15px;
        padding: 10px;
        margin-bottom: 3px;
    }
    .quiz.demo {
        font-size: 0.8rem;
    }

    .quiz.demo .hint {
        font-size: 0.8rem;
    }

    .quiz.demo .heading {
        font-size: 1rem;
    }

    .quiz.demo .options .button {
        font-size: 0.8rem;
    }

    .quiz .demo_text {
        font-size: 1.2rem;
    }
    .quiz .options {
        margin: 0px auto;
        width: 100%;
    }
}